import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { SafeUrlPipe } from 'src/app/commons/pipes/safe.pipe';
import { ScheduleDataService, Location } from 'src/app/store/scheduleDataStore/schedule-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'hs-navigation-map',
  templateUrl: './navigation-map.component.html',
  styleUrls: ['./navigation-map.component.scss']
})
export class NavigationMapComponent implements OnInit {
    //@ViewChild('mapContainer', { static: false }) gmap!: ElementRef;
    //public lat:number = 0;
    //public lng:number = 0;
    public url = "https://www.google.com/maps/embed/v1/place?key="+environment.key_nav_map;
    public safeUrl: SafeResourceUrl = {} as SafeResourceUrl;

    @Input()
    set address(value:string) {
        if (value) {
            this.safeUrl = this.safe.transform(this.url + value);
        }
    }
    constructor(private safe: SafeUrlPipe) { }
    ngOnInit(): void {
        //console.log(this.address);
        //this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + "201301");
        //if (this.address) {
        //    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + this.address);
        //}
    }

    //mapInitializer(lat:number, lng:number) {
    //    let myMap!: google.maps.Map;
    //    const coordinates = new google.maps.LatLng(lat, this.lng);
    //    const mapOptions: google.maps.MapOptions = {
    //        center: coordinates,
    //        zoom: 15,
    //        fullscreenControl: true,
    //        keyboardShortcuts: false,
    //        panControl: false,
    //        disableDefaultUI: true,
            
    //    };
    //    myMap = new google.maps.Map(this.gmap.nativeElement, mapOptions);
    //    if (this.lat && this.lng) {            
    //        let latlngset = new google.maps.LatLng(this.lat, this.lng);
    //        let marker = new google.maps.Marker({
    //            map: myMap,
    //            //title: 'abc',
    //            position: latlngset,
    //            //icon: './assets/images/icons/map-marker.svg',
    //        });
    //    }
    //}

}
