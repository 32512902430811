import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'hs-care-member-map',
  templateUrl: './care-member-map.component.html',
  styleUrls: ['./care-member-map.component.scss']
})
export class CareMemberMapComponent implements OnInit {

  public url = "";
  constructor() { }

  ngOnInit(): void {
    this.url = "https://www.google.com/maps/embed/v1/place?key="+environment.key_nav_map+"&location=46.414382,10.013988";
  }

}
