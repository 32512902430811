// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: '_@_production_@_',
  graph_endpoint:'_@_graph_endpoint_@_',
  authClientID:'_@_authClientID_@_',
  authAuthority:'_@_authAuthority_@_',
  authRedirectURI:'_@_authRedirectURI_@_',
  baseApiUrl:'_@_baseApiUrl_@_',
  apiClientID:'_@_apiClientID_@_',
  videoPlayer:'_@_videoPlayer_@_',
  requestFormLink: '_@_requestFormLink_@_',
  key_nav_map: '_@_key_nav_map_@_',
  saturday_search: '_@_saturday_search_@_',
  connectionString: '_@_connectionString_@_',
  to : '_@_to_@_',
  template_subject: '_@_template_subject_@_'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
