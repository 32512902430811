import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PatientService } from '../api-client/api/patient.service';
import { PatientSearch } from '../api-client/model/patientSearch';
import { Loader } from '../commons-ui/loader';
import { ReferenceDataService } from '../api-client';
import { CommonConstants } from '../commons/common-constants';
import { environment } from 'src/environments/environment';
import { AuthorizationUrlRequest, InteractionType, RedirectRequest, EventType } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { LoggingServiceTsService } from '../store/loggingService/logging.service';

@Component({
  selector: 'hs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [MsalService]
})

export class HeaderComponent implements OnInit,OnDestroy {
  public _typedText: string = "";
  public patentSearch?: PatientSearch[];

  public uiVersion: string = CommonConstants.uiVersion;
  public apiVersion: string = "-";

  private timeoutRef: any;
  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();
  public requestFormLink: string = environment.requestFormLink;

  constructor( 
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private referenceDataService: ReferenceDataService,
    private authService: MsalService,
    private router: Router,
    private broadcastService: MsalBroadcastService,
    private patientService: PatientService ,
    private logging: LoggingServiceTsService ) { }

  ngOnInit(): void {
    this.checkAccount();
 
    this.broadcastService.msalSubject$
    .pipe(
      filter(msg => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      takeUntil(this._destroying$)
    )
    .subscribe((result) => {
      this.checkAccount();
    });
  }

  
  checkAccount() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
    if(!this.loggedIn){
      this.router.navigateByUrl("");
    }
  }

  async login() {
    if (
      this.msalGuardConfig.interactionType === InteractionType.Popup) {
      // this.authService.loginPopup({...this.msalGuardConfig.authRequest!})
      //   .subscribe(() => this.checkAccount());
      this.authService.loginPopup({...this.msalGuardConfig.authRequest!} as AuthorizationUrlRequest)
        .subscribe(() => this.checkAccount());
    } else {
      await this.authService.loginRedirect({...this.msalGuardConfig.authRequest!} as RedirectRequest);
    }
  }

  async logout() {
    this.logging.trackEvent('LogOut', this.logging.createCustomLog('Click on Logout Button', 'Successfully Redirected','INFO'));
    await this.authService.logoutRedirect();
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  public set typedText(text: string) {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
      this.timeoutRef = null;
    }
    this.patentSearch = undefined;
    if (text.length < 3) {
      return;
    }

    this._typedText = text;
    this.timeoutRef = setTimeout(this.search.bind(this), 500);
  }

  public get typedText() {
      return this._typedText;
  }

  private async search(): Promise<void> {
    Loader.showLoader("care-members-results-panel");
    try {
        this.patentSearch = await this.patientService.patientGetPatient(this._typedText).toPromise();
    } finally {
        Loader.hideLoader("care-members-results-panel");
    }
  }

  public schedulePatient(id: number) {
    let url = "/schedule/" + id.toString();

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    this.router.navigate([url]));
    this.patentSearch = undefined;
    this._typedText = "";
  }
}
