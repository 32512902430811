import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from 'src/app/api-client/api/appointment.service';
import { AppointmentDetail } from 'src/app/api-client/model/appointmentDetail';
import { Loader } from 'src/app/commons-ui/loader';
import { CommonConstants } from 'src/app/commons/common-constants';
import { environment } from 'src/environments/environment';

const mobileNoPattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
const emailRegEx = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
declare const $: any;

interface Notification {
  emailId?: string;
  mobileNo?: string;
}
interface Note {
  appointmentId: number;
  createdBy: string;
  createdOn: Date;
  note?: string;
}

@Component({
  selector: 'hs-notification-by-email',
  templateUrl: './notification-by-email.component.html',
  styleUrls: ['./notification-by-email.component.scss']
})
export class NotificationByEmailComponent implements OnInit {
  private appointmentId: number = -1;
  public notes: Note[] = [];
  public comment: string = '';
  public updateEmailPhoneForm: UntypedFormGroup;
  public appointmentStatus: string = '';
  public isDisable = false;

  @Input() appointmentDetail: AppointmentDetail = {
    id: 0,
    patient: undefined,
    preAppointmentTasks: [],
    postAppointmentTasks: [],
    appointmentScheduleDetails: [],
    mobileNo: '',
    emailId: '',
    notificationMobileNo: '',
    notificationEmailId: '',
    address: '',
    addressType: '',
    createdOn: '',
    oneClickMeetingLink: '',
    lat: '',
    lon: '',
    typeId: 0,
    modeId: '',
    timezone: '',
    status: '',
    meetingId: '',
    enableBLSTemplate: false
  };


  @Input() notification: Notification = {
    emailId: '',
    mobileNo: '',
  }

  @Input() set appointmentStatusValue(value: string) {
    this.appointmentStatus = value;
  }

  constructor(private appointmentService: AppointmentService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private datePipe: DatePipe) {
    this.updateEmailPhoneForm = fb.group({
      emailId: ['', [Validators.email]],
      mobileNo: ['']
    })
  }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.appointmentId = params.id;
        }
      );
    this.loadNotes(this.appointmentId);
  }
  private async loadNotes(id: number): Promise<void> {
    this.notes = await this.appointmentService.appointmentGetAppointmentNotes(id).toPromise() as Note[];
  }
  public async saveComment(): Promise<void> {
    try {
      Loader.showLoader("comments-panel");
      await this.appointmentService.appointmentAppointmentNote({ appointmentId: this.appointmentId, note: this.comment }).toPromise();
      this.toastr.success("Comment added!");
    } finally {
      Loader.hideLoader("comments-panel");
    }
    this.loadNotes(this.appointmentId);
    this.comment = '';
  }
  public openNotificationModal(): void {
    this.updateEmailPhoneForm.setValue({ ...this.notification });
    this.updateEmailPhoneForm.controls['emailId'].setValidators([Validators.pattern(emailRegEx)]);
    this.updateEmailPhoneForm.controls['mobileNo'].setValidators([Validators.pattern(mobileNoPattern)]);
    this.updateEmailPhoneForm.controls['emailId'].updateValueAndValidity();
    this.updateEmailPhoneForm.controls['mobileNo'].updateValueAndValidity();
  }
  async onSubmit(): Promise<void> {
    this.notification = { ...this.updateEmailPhoneForm.value };
    $('#notificationModal').modal('hide');
    try {
      Loader.showLoader("notifications-panel");
      await this.appointmentService.appointmentAppointmentNotification({ appointmentId: this.appointmentId, ...this.updateEmailPhoneForm.value }).toPromise();
      this.toastr.success("Changes saved!");
    } finally {
      Loader.hideLoader("notifications-panel");
    }
  }
  public appointmentScheduledTime(): any {
    if (this.appointmentDetail?.appointmentScheduleDetails != undefined) {
      const timezone = this.appointmentDetail.timezone;
      const startDate = new Date(new Date(new Date(this.appointmentDetail.appointmentScheduleDetails[0].scheduleStartDate + "Z").getTime()).toLocaleString('en-US', { timeZone: timezone }));
      const startTime = startDate ? this.datePipe.transform(startDate, 'shortTime') : '';
      return startTime
    }
  }

  public appointmentScheduledDate(): any {
    if (this.appointmentDetail?.appointmentScheduleDetails != undefined) {
      const timezone = this.appointmentDetail.timezone;
      const startDate = new Date(new Date(this.appointmentDetail.appointmentScheduleDetails[0].scheduleStartDate + "Z").toLocaleString('en-US', { timeZone: timezone }));
      return startDate ? this.datePipe.transform(startDate, 'MM/dd/yy') : '';
    }
  }

  public getDuration() {
    if (this.appointmentDetail?.appointmentScheduleDetails != null) {
      const startDate = new Date(this.appointmentDetail.appointmentScheduleDetails[0].scheduleStartDate);
      const endDate = new Date(this.appointmentDetail.appointmentScheduleDetails[0].scheduleEndDate);
      const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
      return differenceInMilliseconds / (1000 * 60);
    }
    else {
      return 0;
    }
  }

  public openOutlook() {
    let recipient = environment.to;
            let subject = environment.template_subject;
            let body = "Account Number: " + CommonConstants.ACCOUNT_NUMBER + "\n" +
              "Date/Time and Time Zone of Scheduled Telehealth Call: " + this.appointmentScheduledDate() + " @ " + this.appointmentScheduledTime() + " " + this.appointmentDetail.timezone + "\n" +
              "Expected Duration of Call: " + this.getDuration() + " min" + "\n" +
              "Language Needed: " + this.appointmentDetail.patient?.language + "\n" +
              "Additional Information: " + " " + "\n" +
              "Link to Telehealth Call: " + this.appointmentDetail.oneClickMeetingLink + "\n";
            let mailtoLink = "mailto:" + recipient + "?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);

            window.open(mailtoLink, '_self');

  };


}
