import { Time } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Loader } from 'src/app/commons-ui/loader';
import { CTMLanguage } from 'src/app/models/common';
import { HSCalendarService } from '../../api-client';
import { CareTeamService } from '../../api-client/api/careTeam.service';
import { AvailableSlotsScheduleResponse } from '../../api-client/model/availableSlotsScheduleResponse';
import { CareMemberInfo } from '../../api-client/model/careMemberInfo';
import { CareMemberInfoRequest } from '../../api-client/model/careMemberInfoRequest';
import { CentralNurseScheduleRequest } from '../../api-client/model/centralNurseScheduleRequest';
import { CentralNurseScheduleResponse } from '../../api-client/model/centralNurseScheduleResponse';
import { PatientModel } from '../../api-client/model/patientModel';
import { ScheduleDaySlotsRequest } from '../../api-client/model/scheduleDaySlotsRequest';
import { FilterCareNursePipe } from '../pipes/filter-care-nurse.pipe';
import { ScheduleService } from '../services/schedule.service';
import { environment } from 'src/environments/environment';

interface SlotAvailabilityErrorMessage {
    daySlotId: string;
    searchStartDate: Date;
    searchEndDate: Date;
}
const setTimeInStr = (newDate: Date): string => {
    const hourse = newDate.getHours();
    const minutes = newDate.getMinutes();
    if (hourse === 12) {
        return `${hourse}:${minutes < 10 ? '0' + minutes : minutes} PM`;
    } else if (hourse > 12) {
        const timeInPm = (hourse - 12);
        return `${timeInPm < 10 ? '0' + timeInPm : timeInPm}:${minutes < 10 ? '0' + minutes : minutes} PM`;
    }
    return `${hourse < 10 ? '0' + hourse : hourse}:${minutes < 10 ? '0' + minutes : minutes} AM`;
}
const calTimeToDisplay = (data: CentralNurseScheduleRequest, type: string) => {
    if (data.startDateTime && data.duration) {
        const date = new Date(data.startDateTime);
        const dateTime = date.getTime();
        let time = dateTime;
        if (type === 'prevous') {
            time = dateTime - (Number(data.duration) * 60 * 1000);
        } else if (type === 'current') {
            time = dateTime + (Number(data.duration) * 60 * 1000);
        } else if (type === 'next') {
            time = dateTime + ((Number(data.duration) + Number(data.duration)) * 60 * 1000);
        }
        const newDate = new Date(time);
        return setTimeInStr(newDate);
    }
    return null;
}


export interface CentralCareMemberInfo extends CareMemberInfo {
    selectedMember?: boolean;
    slots?: CentralNurseScheduleResponse
    startDateTime: Date
}
@Component({
    selector: 'hs-search-central-nurse',
    templateUrl: './search-central-nurse.component.html',
    styleUrls: ['./search-central-nurse.component.scss'],
    providers: [FilterCareNursePipe]
})
export class SearchCentralNurseComponent implements OnInit {
    public readonly durationTimes: string[] = ['90', '60'];
    public centralCareMember: CentralCareMemberInfo[] = [];
    public readonly durationTimeslist: string[] = ['60/60', '90/60'];
    public tempCentralCareMember: CentralCareMemberInfo[] = [];
    public isAvailablitySort: boolean = false;
    public isAutoSearch: boolean = false;
    public modalTittle: string = '';
    public modalDesc: string = '';
    public isNpSearch: boolean = false;

    public centralCareMemberList: CentralCareMemberInfo[] = [];
    public autoSearchScheduleList: AvailableSlotsScheduleResponse[] = [];
    public searchForm: UntypedFormGroup;
    @Output() submitCareNurseEvent = new EventEmitter<CareMemberInfo[]>();
    public patientdetails?: PatientModel;
    private careMemberIds: any = [];
    public beforeSearch: boolean = true;
    public autobeforeSearch: boolean = true;
    public selectedTimezone: string = 'Eastern Standard Time';
    public selectedTimezoneCode: string = 'US/Eastern';
    public nurseType: string = 'CN';
    public times: string[] = [];
    public autoEndDateTime: Date = new Date();
    public autoSearchNoOfDays: any;
    public searchingCareNurse: boolean = false;
    public languages: CTMLanguage[] = [] // LANGUAGES;
    public slotAvailabilityErrorMessage: SlotAvailabilityErrorMessage = {} as SlotAvailabilityErrorMessage;
    public isTca: boolean = false;
    public isPreviousButtonDisabled: boolean = true;
    public isNextButtonDisabled: boolean = true;
    public perviousDates: Date[] = [];
    public dualVisit: boolean = false;
    public showNursePractitioner: boolean = false;
    public isNPEligible: boolean = false;
    public isSaturdaySearch = environment.saturday_search;
    constructor(private fb: UntypedFormBuilder, private careMemberService: CareTeamService,
        private hsCalendarService: HSCalendarService,
        private scheduleService: ScheduleService,
        private filterCareNursePipe: FilterCareNursePipe) {
        this.searchForm = this.fb.group({
            selectedDate: [new Date(), Validators.required],
            selectedTime: [{ hours: 8, minutes: 0 }, Validators.required],
            duration: [this.dualVisit ? '60/60' : '90', Validators.required],
            languageId: [this.isNpSearch ? '' : -211],
            daySlotId: ['D']
        })
    }
    ngOnInit(): void {
        this.scheduleService.$shareTimezonSub.subscribe((value) => {
            this.selectedTimezone = value;
        });

        this.scheduleService.$shareTimezonCodeSub.subscribe((value) => {
            this.selectedTimezoneCode = value;
        });
        this.scheduleService.$shareSearchTypeSub.subscribe((value) => {
            this.nurseType = value;
            if (value === 'NP') {
                this.isAutoSearch = false;
                this.isNpSearch = true;
                this.modalTittle = this.modalDesc = 'Nurse Practitioner(s)';
            }
            if (value === 'CN') {
                this.isAutoSearch = true;
                this.isNpSearch = false;
                this.modalTittle = 'Central Nurse(s)';
                this.modalDesc = 'Central Team Nurses';
            }
            this.checkNpSearch();
        });

        this.scheduleService.$openCentralNurseScheduleModal.subscribe((value: string) => {
            if (value === 'open') {
                this.beforeSearch = true;
                this.autobeforeSearch = true;
                this.centralCareMember = [];
                this.autoSearchScheduleList = [];
                this.isAvailablitySort = false;
            }
        });
        this.fetchCTMLanguageList();
    }
    @Input()
    public set patient(patient: PatientModel) {
        this.patientdetails = patient;
        this.showHideDualVisit();
    }
    public showHideDualVisit(): void {
        this.isNPEligible = this.patientdetails?.npEligibilityStatus?.toLocaleLowerCase() == 'yes';
    }
    private fetchCTMLanguageList(): void {
        this.careMemberService.careTeamGetPreferredLanguageList().subscribe((langList: CTMLanguage[]) => {
            this.languages = [...langList];
        })
    }
    private checkNpSearch(): void {
        if (this.isNpSearch === true) {
            this.searchForm = this.fb.group({
                selectedDate: [new Date(), Validators.required],
                selectedTime: [{ hours: 8, minutes: 0 }, Validators.required],
                duration: ['90', Validators.required],
                languageId: [''],
                daySlotId: ['D']
            })
        }
        else {
            this.searchForm = this.fb.group({
                selectedDate: [new Date(), Validators.required],
                selectedTime: [{ hours: 8, minutes: 0 }, Validators.required],
                duration: [this.dualVisit ? '60/60' : '90', Validators.required],
                languageId: [-211],
                daySlotId: ['D']
            })
        }
    } private InitiateDualVisitList(): void {
        if (this.dualVisit === true) {
            this.searchForm = this.fb.group({
                selectedDate: [new Date(), Validators.required],
                selectedTime: [{ hours: 8, minutes: 0 }, Validators.required],
                duration: ['60/60', Validators.required],
                languageId: [-211],
                daySlotId: ['D']
            })
        }
        else {
            this.searchForm = this.fb.group({
                selectedDate: [new Date(), Validators.required],
                selectedTime: [{ hours: 8, minutes: 0 }, Validators.required],
                duration: ['90', Validators.required],
                languageId: [-211],
                daySlotId: ['D']
            })
        }
    }

    public fetchCentralNurseList(): void {
        if (this.patientdetails && this.patientdetails.state) {
            Loader.showLoader("centralNurseScheduleModalBody");
            this.beforeSearch = false;
            const requestPayload: CareMemberInfoRequest = {
                stateId: Number(this.patientdetails.state[0]),
                duration: this.searchForm.value.duration,
                startDateTime: `${this.searchForm.value.selectedDate.toLocaleDateString('en-US')}`,
                timeZone: this.selectedTimezone,
                languageId: this.searchForm.value.languageId ? this.searchForm.value.languageId : null,
                nurseType: this.nurseType,
            };
            this.careMemberService.careTeamGetCentralNurseByPatientStateId(requestPayload).subscribe((data: any) => {
                this.centralCareMemberList = data;
                console.log(data);
                this.careMemberIds = this.centralCareMemberList.map(({ id, emailId }) => { return { careMemberId: id, emailId: emailId } });
                Loader.hideLoader("centralNurseScheduleModalBody");
                this.filterAvailability();
            });
        }
    }

    public set time(data: Time) {
        this.searchForm.controls.selectedTime.setValue(data)
    }
    private storeSlotTimes(data: CentralNurseScheduleRequest): void {
        const prev = calTimeToDisplay(data, 'prevous');
        const next = calTimeToDisplay(data, 'next');
        const current = calTimeToDisplay(data, 'current');
        const selected = calTimeToDisplay(data, '');
        this.times = [];
        if (prev && next && selected && current) {
            this.times.push(prev);
            this.times.push(selected);
            this.times.push(current);
            this.times.push(next);
        }
    }

    public filterAvailability(): void {
        this.centralCareMember = [];
        this.isAvailablitySort = false;
        let counter = 0;

        if (this.careMemberIds.length > 0) {
            this.searchingCareNurse = true;
        }

        this.careMemberIds.forEach((element: any) => {
            Loader.showLoader("centralNurseScheduleModalBody");
            const data: CentralNurseScheduleRequest = {
                careMemberId: element.careMemberId,
                emailId: element.emailId,
                duration: this.searchForm.value.duration,
                startDateTime: `${this.searchForm.value.selectedDate.toLocaleDateString('en-US')}, ${this.searchForm.value.selectedTime.hours}:${this.searchForm.value.selectedTime.minutes}`,
                timeZone: this.selectedTimezone
            };
            this.storeSlotTimes(data);
            this.hsCalendarService.hSCalendarGetCentralNurseSchedules(data).subscribe((data: any) => {
                if (counter == 0) {
                    Loader.hideLoader("centralNurseScheduleModalBody");
                }
                counter++;
                if (data != null) {
                    let obj = this.centralCareMemberList.filter(x => x.id == data.careMemberId);
                    if (obj.length > 0) {
                        obj[0].slots = data;
                        obj[0].scheduleStartDate = data.startDateTime;
                        this.centralCareMember.push(obj[0]);

                        this.centralCareMember.sort((a, b) => a.appointmentCount - b.appointmentCount);
                        this.tempCentralCareMember = this.centralCareMember.map(object => ({ ...object }))
                    }
                }
                if (counter == this.careMemberIds.length) {
                    this.searchingCareNurse = false;
                }
            })
        });
    }
    public enableSubmitButton(): boolean {
        const data: CareMemberInfo[] = this.centralCareMember.filter((item) => item.selectedMember);
        return data.length > 0 ? true : false;
    }

    public enableAutoSearchSubmitButton(): boolean {
        const data: AvailableSlotsScheduleResponse[] = this.autoSearchScheduleList.filter((item) => item.selectedMember);
        return data.length > 0 ? true : false;
    }

    public submitSchedules(): void {
        const data: CareMemberInfo[] = this.centralCareMember.filter((item) => item.selectedMember);
        this.submitCareNurseEvent.emit(data);
    }

    public submitAutoSearchSchedules(item: any): void {
        let careMemberInfo: CareMemberInfo[] = [];
        let obj: CareMemberInfo = {
            id: item.careMemberId!,
            name: item.name,
            emailId: item.emailId,
            schedulerId: 0,
            scheduleStartDate: item.startDateTime!,
            scheduleEndDate: item.endDateTime!,
            schedulePreTime: 0,
            schedulePostTime: 0,
            appointmentCount: 0,
            type: item.type,
            distance: 0
        };
        if (this.showNursePractitioner) {
            let nursePractitioner: CareMemberInfo = {
                id: item.nursePractitioner.careMemberId!,
                name: item.nursePractitioner.name,
                emailId: item.nursePractitioner.emailId,
                schedulerId: 0,
                scheduleStartDate: item.startDateTime!,
                scheduleEndDate: item.endDateTime!,
                schedulePreTime: 0,
                schedulePostTime: 0,
                appointmentCount: 0,
                type: item.nursePractitioner.type,
                distance: 0
            };
            careMemberInfo.push(nursePractitioner);
        }
        careMemberInfo.push(obj);
        this.submitCareNurseEvent.emit(careMemberInfo);
        if (this.showNursePractitioner) return;
        this.scheduleService.openCareMemberEvents(obj);
    }

    public sortByAvailablity(event: any): void {
        if (event.target.checked) {
            this.centralCareMember.sort((a: any, b: any) => a.slots.slot2 - b.slots.slot2);
        }
        else {
            this.centralCareMember = this.tempCentralCareMember.map(object => ({ ...object }))
        }
        this.isAvailablitySort = event.target.checked;
    }

    public autoSearch(event: any): void {
        this.isAutoSearch = event.target.checked;
        this.autobeforeSearch = true;
    }

    public tcaSearch(event: any): void {
        this.isTca = event.target.checked;
    }
    public dualSearch(event: any): void {
        this.dualVisit = event.target.checked;
        this.InitiateDualVisitList();
    }

    public fetchAvailableScheduleList(): void {
        if (this.isAutoSearch) {
            this.centralCareMember = [];
            this.fetchAutoSearchList();
        }
        else {
            this.autoSearchScheduleList = [];
            this.fetchCentralNurseList();
        }
        if (this.perviousDates.length == 0) {
            this.perviousDates.push(this.searchForm.value.selectedDate);
        }
        this.showNursePractitioner = this.dualVisit;
    }

    public fetchAutoSearchList(): void {
        if (this.patientdetails && this.patientdetails.state) {
            Loader.showLoader("autoCentralNurseScheduleModalBody");
            this.autobeforeSearch = true;
            const requestPayload: ScheduleDaySlotsRequest = {
                duration: this.searchForm.value.duration,
                startDate: `${this.searchForm.value.selectedDate.toLocaleDateString('en-US')}`,
                timeZone: this.selectedTimezone,
                languageId: this.searchForm.value.languageId ? this.searchForm.value.languageId : null,
                daySlot: this.searchForm.value.daySlotId ? this.searchForm.value.daySlotId : null,
                nurseType: this.nurseType,
                stateId: Number(this.patientdetails.state[0]),
                isTCA: this.isTca,
                isDualVisit: this.dualVisit
            };
            this.isNextButtonDisabled = false;
            if (this.searchForm.value.selectedDate.toLocaleDateString('en-US') == new Date().toLocaleDateString('en-US')) {
                this.isPreviousButtonDisabled = true;
            }
            else {
                this.isPreviousButtonDisabled = false;
            }
            this.searchingCareNurse = true;
            this.hsCalendarService.hSCalendarGetNurseSchedulesDaySlots(requestPayload).subscribe((data: any) => {
                this.autoSearchScheduleList = data;
                this.slotAvailabilityErrorMessage = {
                    daySlotId: requestPayload.daySlot ? requestPayload.daySlot : '',
                    searchStartDate: this.searchForm.value.selectedDate.toLocaleDateString('en-US'),
                    searchEndDate: data[0].searchEndDateTime
                }
                this.autoSearchScheduleList.forEach((item: AvailableSlotsScheduleResponse) => {
                    var startdate = new Date(item.startDateTime);
                    item.startDateTime = new Date(startdate.toLocaleString('en-US', { timeZone: this.selectedTimezoneCode }));

                    var enddate = new Date(item.endDateTime);
                    item.endDateTime = new Date(enddate.toLocaleString('en-US', { timeZone: this.selectedTimezoneCode }));
                });
                Loader.hideLoader("autoCentralNurseScheduleModalBody");
                this.searchingCareNurse = false;
                this.autobeforeSearch = false;
            });
        }
    }

    public afterFetchAutoSearchList($event: Event): void {
        $event.stopPropagation();
        let length = this.autoSearchScheduleList.length;
        let date = new Date(this.autoSearchScheduleList[length - 1].endDateTime);
        let convertedDate = new Date(date.setDate(date.getDate() + 1));
        this.perviousDates.push(convertedDate);
        this.searchForm.controls.selectedDate.setValue(convertedDate);
        this.fetchAutoSearchList();
    }

    public previousFetchAutoSearchList($event: Event): void {
        $event.stopPropagation();
        let lastIndex = this.perviousDates.length - 2;
        this.searchForm.controls.selectedDate.setValue(this.perviousDates[lastIndex]);
        this.perviousDates.splice(this.perviousDates.length - 1);
        this.fetchAutoSearchList();
    }

    public ResetFetchAutoSearchList($event: Event): void {
        $event.stopPropagation();
        this.perviousDates = [];
        var date = new Date(new Date().toLocaleDateString('en-US'));
        this.searchForm.controls.selectedDate.setValue(date);
        this.perviousDates.push(date);
        this.fetchAutoSearchList();
    }
}
